<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Signature") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >

        <v-row class="pt-5">
          <v-col cols="12">
            {{ $t('Voulez-vous marquer ce order') }} <strong>'{{ item.title }}'</strong> {{ $t('comme étant signé ?') }}
          </v-col>
<!--          <v-col
            cols="12"
          >
            <v-select
              v-model="item.status_id"
              :label="$t('Statut')"
              :rules="[validators.required]"
              :error-messages="errorMessages.status_id"
              :items="$store.state['app-order'].statuses"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
            >
            </v-select>
          </v-col>-->
<!--          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.title"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
              :label="$t('Titre')"
              :placeholder="$t('Titre')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.author_id"
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
              :label="$t('Author')"
              item-text="full_name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="authors"
              :loading="isLoadingAuthors"
              :search-input.sync="search"
              clearable
            >
              <template #item="{ item }">
                <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
              </template>
              <template #selection="{ item }">
                <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
              </template>
              <template #no-data>
                <v-dialog
                  v-model="isFormAuthorActive"
                  touchless
                  :right="!$vuetify.rtl"
                  :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span>{{ $t("L'auteur.") }} <strong>{{ search }}</strong> {{ $t("n'existe pas.") }}</span>
                    <v-btn
                      depressed
                      small
                      color="primary"
                      @click="newAuthor"
                    >
                      <span>{{ $t("Créer l'auteur") }}</span>
                    </v-btn>
                  </template>
                  <author-form
                    v-model="item.author"
                    :is-form-active="isFormAuthorActive"
                    :with-addresses="false"
                    @refetch-data="updateAuthors"
                  >
                    <template v-slot:form-title>
                      <span class="font-weight-semibold text-base text&#45;&#45;primary">{{ item.id ? $t('Modifier'): $t('Ajouter un auteur') }}</span>
                      <v-spacer />
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        small
                        @click="isFormAuthorActive = !isFormAuthorActive"
                      >
                        <v-icon size="22">
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </author-form>
                </v-dialog>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.genre_id"
              :label="$t('Genre')"
              :rules="[validators.required]"
              :error-messages="errorMessages.genre_id"
              :items="$store.state['app'].genres"
              item-text="label"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_words"
              type="number"
              outlined
              min="0"
              dense
              :label="$t('Nombre de mots')"
              :placeholder="$t('Nombre de mots')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_pages"
              type="number"
              outlined
              :rules="[ item.custom_pages ?validators.required:true,]"
              :error-messages="errorMessages.sum_pages"
              :label="$t('Nombre de pages')"
              :placeholder="$t('Nombre de pages')"
              :readonly="!item.custom_pages"
              :append-icon="item.custom_pages ? icons.mdiPencilOffOutline : icons.mdiPencilOutline"
              dense
              hide-details="auto"
              @click:append="item.custom_pages = !item.custom_pages"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.public_price"
              type="number"
              outlined
              :rules="[ item.custom_price ?validators.required:true,]"
              :error-messages="errorMessages.public_price"
              :label="$t('Prix public')"
              :readonly="!item.custom_price"
              :append-icon="item.custom_price ? icons.mdiPencilOffOutline : icons.mdiPencilOutline"
              dense
              hide-details="auto"
              @click:append="item.custom_price = !item.custom_price"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_copies"
              outlined
              type="number"
              min="0"
              dense
              :label="$t('Nombre d\'exemplaires')"
              :placeholder="$t('Nombre d\'exemplaires')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.discount_price"
              outlined
              type="number"
              min="0"
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.discount_price"
              :label="$t('Prix d\'auteur.value.addresses')"
              :placeholder="$t('Prix d\'auteur.value.addresses')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.source_id"
              :label="$t('Source')"
              :rules="[validators.required]"
              :error-messages="errorMessages.source_id"
              :items="$store.state['app'].sources"
              item-text="source_name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.format_id"
              :label="$t('Format')"
              :error-messages="errorMessages.format_id"
              :items="$store.state['app'].formats"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.country_id"
              :label="$t('Pays')"
              :error-messages="errorMessages.country_id"
              :items="$store.state['app-order'].pays"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-dialog
              ref="dialog"
              v-model="modalReceipt"
              :return-value.sync="item.receipt_date"
              persistent
              width="290px"
              :label="$t('Date de réception')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.receipt_date"
                  :label="$t('Date de réception')"
                  :append-icon="icons.mdiCalendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.receipt_date"
                locale="fr"
                scrollable
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalReceipt = false"
                >
                  {{ $t("") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(item.receipt_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>-->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
          <v-spacer />
          <slot name="form-cancel">
              <v-btn
                :loading="loading"
                color="secondary"
                outlined
                type="reset"
                @click="dialog = false"
              >
                {{ $t('Annuler') }}
              </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'

export default {
  components: { AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {
    } = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingOrders = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const newAuthor = () => {
      item.value.author = {
        full_name: search.value, email: null, phone: null, addresses: [],
      }
      isFormAuthorActive.value = true
    }
    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        authors.value = response.data.data
      })
    }
    axios.get('/fees').then(response => {
      fees.value = response.data.data
    })
    const updateAuthors = author => {
      authors.value.push(author)
      item.value.author_id = author.id
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('app-order/actionOnItem', { path: `/orders/action/signature/${item.value.id}`, item: { id: item.value.id, status_id: item.value.status_id } })
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    searchAuthors('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })
    const calculatePrice = () => {
      const fee = fees.value.find(i => i.page_min <= item.value.sum_pages && i.page_max >= item.value.sum_pages)

      if (fee && (item.value.sum_pages)) {
        item.value.public_price = parseFloat(fee.frais)/* + (parseFloat(item.value.sum_pages - fee.subtraction) * fee.percentage).toFixed(2) */
        console.log(item.value.public_price)
      } else {
        item.value.public_price = store.state.app.settings.default_pages_price || 27
      }
    }
    const calculatePages = () => {
      // item.value.sum_pages = null
      if (!item.value.custom_pages && item.value.sum_words >= 0) {
        const words = store.state.app.settings.number_words_per_page || 260
        item.value.sum_pages = Math.ceil(item.value.sum_words / words)
      } else {
        console.log('test')
        item.value.sum_pages = null
      }
    }
    const nbPages = computed({
      get: () => item.value.sum_pages,

      /* set: value => emit('update:object', value), */
    })
    const nbWords = computed({
      get: () => item.value.sum_words,
    })
    watch(nbPages, val => {
      calculatePrice()

      return ''
    })
    watch(nbWords, val => {
      calculatePages()

      return ''
    })

    store
      .dispatch('app/fetchSources', { per_page: 500 })

    return {
      resetItem,
      form,
      isFormAuthorActive,
      isLoadingOrders,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      onSubmit,
      updateAuthors,
      modalReceipt,
      formatDate,
      newAuthor,
      item,
      valid,
      dialog,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
