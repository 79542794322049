<template>
  <div id="order-list">
    <!-- app drawer -->

    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 700 : '100%'"
    >
      <order-form
        v-if="isFormActive"
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un order') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </order-form>
    </v-dialog>
    <v-dialog

      v-model="isPropositionActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <order-proposition-form
        v-if="isPropositionActive"
        :is-form-active="isPropositionActive"
        :selected="selectedRows"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Modifier la date de la proposition')}}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isPropositionActive = !isPropositionActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isPropositionActive = !isPropositionActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </order-proposition-form>
    </v-dialog>
    <v-dialog
      v-model="isRelanceActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <order-date-relance-form
        v-if="isRelanceActive"
        :is-form-active="isRelanceActive"
        :selected="selectedRows"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Modifier la date de date de relance')}}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRelanceActive = !isRelanceActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isRelanceActive = !isRelanceActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </order-date-relance-form>
    </v-dialog>
    <v-dialog

      v-model="signatureDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <signature-form
        v-if="signatureDialog"
        v-model="item"
        :is-form-active="signatureDialog"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un order') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="signatureDialog = !signatureDialog"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="signatureDialog = !signatureDialog"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </signature-form>
    </v-dialog>
    <v-dialog
      v-model="accordDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <accord-form
        v-if="accordDialog"
        v-model="item"
        :is-form-active="accordDialog"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Accord Signature') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="accordDialog = !accordDialog"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="accordDialog = !accordDialog"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </accord-form>
    </v-dialog>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Commandes") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer />
        <export-excel
          v-if="$can('download','Order')"
          :options="downloadObject"
          @refetch-data="fetchItems"
        />
        <Actions
          :headers="filters.filter(ele => ele.group && $can('edit_' + ele.value, 'Order'))"
          :selected="selectedRows"
          completed-field="publication.state"
          :config="{ url: '/orders/action/', author: 'author', resource: 'Order'}"
          @refetch-data="fetchItems"
          @saved="saved"
          @select="select"
        />
        <v-btn
          v-if="$can('edit','Order')"
          color="primary"
          class=" me-3"
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Ajouter une commande") }}</span>
        </v-btn>
<!--        <v-btn
          v-if="$can('edit_proposal_date','Order')"
          color="primary"
          class="me-3"
          :disabled="!selectedRows.length"
          @click.stop="openPropositionDialog(false)"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
          <span>{{ $t("proposition") }}</span>
        </v-btn>
        <v-btn
          v-if="$can('edit_relaunch_date','Order')"
          color="primary"
          class="me-3"
          :disabled="!selectedRows.length"
          @click.stop="openRelanceDialog(false)"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
          <span>{{ $t("Relance") }}</span>
        </v-btn>-->
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table orders-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="true"
        dense fixed-header :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" :show-select="true"/>
        </template>
        <template #item="options">
          <Row
            :options="options"
            :show-select="true"
            completed-field="publication.state"
            :config="{ url: '/orders/action/', author: 'author', resource: 'Order'}"
            @refetch-data="fetchItems"
            @saved="saved"
            @select="select"
          >
        <!-- name -->
        <template #[`item.author.full_name`]="{item,index}">
          <author-widget :object="item.author" />
<!--          <div class="d-flex align-center">
            <v-avatar
              v-if="item.author"
              :color="item.author.avatar ? '' : 'primary'"
              :class="item.author.avatar ? '' : 'v-avatar-light-bg primary&#45;&#45;text'"
              size="30"
            >
              <v-img
                v-if="item.author.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.author.full_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3" v-if="item.author">
              <router-link
                :to="{ name : 'author-view', params : { id : item.author_id } }"
                class="text&#45;&#45;primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.author.full_name }}
              </router-link>
              &lt;!&ndash;              <small>@{{ item.ordername }}</small>&ndash;&gt;
            </div>
          </div>-->
        </template>
        <template #[`item.author.old_author`]="{item}">
          <v-icon
            v-if="item.author && item.author.old_author"
            small
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
        </template>
<!--        <template #[`item.author.minor_author`]="{item}">
          <v-icon
            v-if="item.author && item.author.minor_author"
            small
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
        </template>-->
<!--        <template #[`item.author.particular_case`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.author && item.author.particular_case === 'yes'"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>-->
        <template #[`item.publish`]="{item}">
          <div class="text-center" v-if="item.publish">
            {{ item.publication? item.publication.id : ''}}
          </div>
        </template>
        <template #[`item.public_price`]="{item}">
          <span>{{ item.public_price.toFixed(2)}}</span>
        </template>
            <template #[`item.email`]="{item}">
              {{ item.email?item.email.join(', '):'' }}
            </template>
        <!-- pay -->
        <template #[`item.country`]="{item}">
          <div
            v-if="item.country"
            class="d-flex align-center"
          >

            <span class="text-capitalize">{{ item.country.name }}</span>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="isCompleted(item.publication)"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>

              <v-list-item
                v-if="$can('edit','Order')"
                @click="openDialog(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>{{ $t('Modifier') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$can('delete','Order')"
                @click="confirmDelete(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span><span>{{ $t('Supprimer') }}</span></span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiMicrosoftExcel,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckOutline,
  mdiCalendar,
  mdiMenuDown,
} from '@mdi/js'
import {onUnmounted, ref, watch} from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import OrderForm from './OrderForm.vue'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import SignatureForm from '@/views/apps/orders/SignatureForm'
import AccordForm from '@/views/apps/orders/AccordForm'
import HeaderFilters from '@/components/HeaderFilters'
import ExportExcel from '@/components/ExportExcel'
import OrderPropositionForm from '@/views/apps/orders/OrderPropositionForm'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";
import Actions from "@/components/Actions";
import OrderDateRelanceForm from "@/views/apps/orders/OrderDateRelanceForm";
import AuthorWidget from "@/views/apps/author/author-view/components/AuthorWidget.vue";

export default {
  components: {
    AuthorWidget,
    OrderDateRelanceForm,
    Actions,
    Row,
    TableRow,
    OrderPropositionForm,
    ExportExcel,
    HeaderFilters,
    AccordForm,
    SignatureForm,
    RowsPerPage,
    OrderForm,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      saved,
      item,
      items,
      downloadDialog,
      downloadObject,
      signatureDialog,
      accordDialog,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      orderTotalLocal,
      selectedRows,
      select,
      isFormActive,
      isPropositionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      isRelanceActive,
      openRelanceDialog,
      openDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,
      resolveOrderRoleVariant,
      resolveOrderRoleIcon,
      resolveOrderStatusVariant,
      resolveOrderStatusName,
      resolveOrderTotalIcon,
    } = controller()

    const superiors = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Order', value: 'order' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]

    /* store
      .dispatch('app-order/fetchCountries', { per_page: 500 }) */
    /* store
      .dispatch('app-order/fetchSources', { per_page: 500 }) */
    /* store
      .dispatch('app/fetchGenres', { per_page: 500 }) */
    /* store
      .dispatch('app-order/fetchFormats', { per_page: 500, type: 'order' }) */
    /* store
      .dispatch('app-order/fetchStatuses', { per_page: 500, status_type: 'order' }) */

    const assistants = ref([])
    axios
      .get('/listWorkers', {
        params: {
          term: '', type: 'order', /*user: JSON.parse(localStorage.getItem('userData')).id || null,*/ rowsPerPage: 100,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'user.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
          filters.value[indexH].filters[0].values = assistants.value
        }
      })

    axios
      .get('/order/settings', {
        params: {
        },
      })
      .then(response => {
        if(response.data && response.data.quantities) {
          const indexH = filters.value.findIndex(ele => ele.value === 'quantity')
          if (indexH > 0) {
            filters.value[indexH].filter.values = assistants.value
            filters.value[indexH].filters[0].values = response.data.quantities.map((ele) => {
              return {
                id: ele.quantity, label: ele.quantity,
              }})
          }
        }
      })

    axios
      .get('/countries', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app-order/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'country.full_name')
        if (indexH > 0) {
          filters.value[indexH].filters[0].values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/statuses', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app-order/statuses'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/sources', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app/sources'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'source.source_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
        const indexO = filters.value.findIndex(ele => ele.value === 'origin.source_name')
        if (indexO > 0) {
          filters.value[indexO].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/formats', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app-order/formats'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'format.name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/genres', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app/genres'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'genre.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/destinations', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state.app.destinations = response.data.data
        store.state['app/destinations'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'destination.name')
        if (indexH > 0) {
          filters.value[indexH].filters[0].values = response.data.data
          filters.value[indexH].fields[0].items = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/printers', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state.app.printers = response.data.data
        store.state['app/printers'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'printer.name')
        if (indexH > 0) {
          filters.value[indexH].filters[0].values = response.data.data
          filters.value[indexH].fields[0].items = response.data.data
        }
      })
      .catch(error => console.log(error))

    filters.value = tableColumns
   /*

   console.log(filters.value.filter(ele => ele.value != 'actions').map(ele => {
      return `"${ele.text}" => $order->${ele.value.replace('.','->')}`
    }))

    */

    const windowHeight = ref(window.innerHeight)
    const tableHeight = ref(window.innerHeight - 250)
    window.addEventListener('resize', () => {
      windowHeight.value = window.innerHeight
    })
    watch(windowHeight, () => {
      tableHeight.value = windowHeight.value - 250
    })

    return {
      tableHeight,
      saved,
      item,
      items,
      downloadDialog,
      downloadObject,
      superiors,
      filters,
      tableColumns,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      accordDialog,
      signatureDialog,
      orderTotalLocal,
      isPropositionActive,
      isFormActive,
      selectedRows,
      select,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      isRelanceActive,
      openRelanceDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolveOrderRoleVariant,
      resolveOrderRoleIcon,
      resolveOrderStatusVariant,
      resolveOrderStatusName,
      resolveOrderTotalIcon,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiMicrosoftExcel,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckOutline,
        mdiCalendar,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
